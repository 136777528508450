export default function Usdc({ size = 45 }: { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="usdc"
      x={0}
      y={0}
      enableBackground="new 0 0 500 500"
      width={size}
      height={size}
      viewBox="0 0 500 500"
    >
      <style>{'.st1{fill:#fff}'}</style>
      <circle
        cx={250}
        cy={250}
        r={248.3}
        style={{
          fill: '#2775ca',
        }}
      />
      <path
        d="M203.4 422.7c0 5.8-4.7 9.1-10.1 7.4C118.1 406 63.8 335.7 63.8 252.5c0-83 54.3-153.4 129.6-177.5 5.6-1.7 10.1 1.6 10.1 7.4v14.5c0 3.9-2.9 8.3-6.6 9.7-59.6 21.9-102 79.1-102 145.9 0 66.9 42.5 124 102 145.7 3.7 1.4 6.6 5.8 6.6 9.7v14.8z"
        fill="#FFFFFF"
      />
      <path
        d="M265.5 368.9c0 4.3-3.5 7.8-7.8 7.8h-15.5c-4.3 0-7.8-3.5-7.8-7.8v-24.4c-33.9-4.7-50.4-23.5-54.9-49.5-.8-4.5 2.7-8.3 7.2-8.3h17.7c3.7 0 6.8 2.7 7.6 6.2 3.3 15.3 12.2 27.2 39.4 27.2 20 0 34.3-11.3 34.3-27.9 0-16.7-8.3-23.1-37.8-27.9-43.5-5.8-64-19-64-53 0-26.2 20-46.8 50.6-51v-24.1c0-4.3 3.5-7.8 7.8-7.8h15.5c4.3 0 7.8 3.5 7.8 7.8v24.6c25 4.5 40.9 18.6 46.2 42.3 1 4.5-2.5 8.5-7.2 8.5h-16.3c-3.5 0-6.4-2.3-7.4-5.6-4.5-14.9-15.1-21.5-33.8-21.5-20.6 0-31.2 9.9-31.2 23.9 0 14.7 6 22.1 37.6 26.6 42.7 5.8 64.8 18 64.8 54.3 0 27.5-20.6 49.9-52.6 54.9V369h-.2z"
        fill="#FFFFFF"
      />
      <path
        d="M306.6 430c-5.6 1.7-10.1-1.6-10.1-7.4v-14.5c0-4.3 2.5-8.3 6.6-9.7 59.4-21.7 102-79 102-145.7 0-66.9-42.5-124-102-145.7-3.7-1.4-6.6-5.8-6.6-9.7V82.8c0-5.8 4.7-9.1 10.1-7.4 75.3 23.7 129.6 94.1 129.6 177.1 0 83.2-54.3 153.5-129.6 177.5z"
        fill="#FFFFFF"
      />
    </svg>
  )
}
