function ErrorNotice() {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.178231 12.0312L6.84386 0.65625C7.35417 -0.21875 8.64198 -0.21875 9.15573 0.65625L15.8182 12.0312C16.331 12.9031 15.6923 14 14.6645 14H1.33323C0.309481 14 -0.331144 12.9062 0.178231 12.0312ZM8.75011 4.25C8.75011 3.83594 8.41417 3.5 8.00011 3.5C7.58604 3.5 7.25011 3.8375 7.25011 4.25V8.25C7.25011 8.66406 7.58604 9 7.97198 9C8.35792 9 8.75011 8.66563 8.75011 8.25V4.25ZM8.00011 12C8.54261 12 8.98261 11.56 8.98261 11.0175C8.98261 10.475 8.54292 10.035 8.00011 10.035C7.45729 10.035 7.01761 10.475 7.01761 11.0175C7.01886 11.5594 7.45636 12 8.00011 12Z"
        fill="#D80027"
      />
    </svg>
  )
}

export default ErrorNotice
