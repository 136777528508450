function ForumBlue({ size = 90 }: { size?: number }) {
  return (
    <svg width={size} height={size} viewBox="0 0 96 97" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M48.4071 0.8125C22.1357 0.8125 0 21.7822 0 47.6697C0 48.5 0.0214286 96.1875 0.0214286 96.1875L48.4071 96.1449C74.7 96.1449 96 74.3662 96 48.4787C96 22.5912 74.7 0.8125 48.4071 0.8125ZM48 75.75C43.8429 75.75 39.8786 74.8346 36.3429 73.174L18.9643 77.4531L23.8714 61.4863C21.7714 57.633 20.5714 53.2049 20.5714 48.5C20.5714 33.4486 32.85 21.25 48 21.25C63.15 21.25 75.4286 33.4486 75.4286 48.5C75.4286 63.5514 63.15 75.75 48 75.75Z"
        fill="#1768E9"
      />
    </svg>
  )
}

export default ForumBlue
