function Forum({ size = 24, color = 'var(--black-800)' }: { size?: number; color?: string }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="2 0 24 24">
      <path
        fill={color}
        fillRule="evenodd"
        d="M19.418 6.11h-1.582v-.528c0-.872-.71-1.582-1.582-1.582H4.582C3.71 4 3 4.71 3 5.582v8.438c0 .872.71 1.582 1.582 1.582h1.582v2.636c0 .47.57.703.9.373l.9-.9h5.962l3.01 3.01c.33.33.9.098.9-.373V17.71h1.582C20.29 17.71 21 17 21 16.129V7.69c0-.872-.71-1.582-1.582-1.582zm-12.2 10.855v-1.89a.527.527 0 00-.527-.528H4.582a.528.528 0 01-.527-.527V5.582c0-.29.236-.527.527-.527h11.672c.29 0 .527.236.527.527v8.438c0 .29-.236.527-.527.527H9.855a.527.527 0 00-.372.154l-2.264 2.264zm12.2-.309c.29 0 .527-.236.527-.527V7.69a.528.528 0 00-.527-.527h-1.582v6.856c0 .872-.71 1.582-1.582 1.582h-6.18l-1.055 1.054h5.126c.14 0 .274.056.372.155l2.264 2.264v-1.891c0-.292.236-.528.528-.528h2.109z"
        clipRule="evenodd"
      ></path>
      <path
        fill={color}
        d="M13.09 7.164H5.637a.527.527 0 000 1.055h7.453a.527.527 0 100-1.055zM15.2 9.273H5.636a.527.527 0 000 1.055h9.562a.527.527 0 100-1.055zM10.98 11.383H5.637a.527.527 0 000 1.055h5.343a.527.527 0 000-1.055z"
      ></path>
    </svg>
  )
}

export default Forum
