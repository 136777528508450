export default function Gear() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="none" viewBox="0 0 12 12">
      <path
        fill="#736E7D"
        d="M11.967 3.905a.511.511 0 01-.16.577l-1.076.924a4.288 4.288 0 010 1.19l1.077.924a.511.511 0 01.159.577c-.11.279-.242.546-.393.804l-.117.19a5.99 5.99 0 01-.55.731.585.585 0 01-.609.16l-1.385-.415a4.86 4.86 0 01-1.094.595l-.31 1.339a.548.548 0 01-.453.417 6.81 6.81 0 01-2.114 0 .548.548 0 01-.453-.417l-.31-1.339a4.86 4.86 0 01-1.095-.595l-1.382.417a.59.59 0 01-.61-.16 5.981 5.981 0 01-.549-.73l-.117-.19a5.66 5.66 0 01-.393-.805.511.511 0 01.16-.576l1.076-.924a4.34 4.34 0 010-1.193L.192 4.482a.511.511 0 01-.159-.577 5.66 5.66 0 01.393-.804l.117-.19a5.98 5.98 0 01.55-.73.585.585 0 01.609-.16l1.385.415A4.864 4.864 0 014.18 1.84l.31-1.338a.548.548 0 01.453-.418A6.807 6.807 0 017.058.082a.548.548 0 01.453.417l.31 1.339c.393.152.761.354 1.095.595l1.385-.415a.59.59 0 01.609.16c.201.23.385.473.55.731l.116.19c.152.258.284.525.393.804l-.002.002zM6 7.877c.528 0 1.034-.198 1.407-.55.373-.351.583-.828.583-1.326 0-.497-.21-.974-.583-1.326A2.052 2.052 0 006 4.125c-.527 0-1.033.198-1.406.55a1.823 1.823 0 00-.583 1.326c0 .498.21.975.583 1.326.373.352.879.55 1.406.55z"
      ></path>
    </svg>
  )
}
