function OutcomeArrow() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.03703 12.2929C3.64651 12.6834 3.64651 13.3166 4.03703 13.7071C4.42756 14.0976 5.06072 14.0976 5.45125 13.7071L4.03703 12.2929ZM17.7441 1C17.7441 0.447716 17.2964 4.87118e-07 16.7441 2.34237e-07L7.74414 1.03503e-06C7.19186 6.97852e-07 6.74414 0.447716 6.74414 1C6.74414 1.55229 7.19186 2 7.74414 2L15.7441 2L15.7441 10C15.7441 10.5523 16.1919 11 16.7441 11C17.2964 11 17.7441 10.5523 17.7441 10L17.7441 1ZM5.45125 13.7071L17.4512 1.70711L16.037 0.292894L4.03703 12.2929L5.45125 13.7071Z"
        fill="white"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.27669 9.06776C5.02438 9.02386 4.76486 9.00098 4.5 9.00098C2.01472 9.00098 -8.80661e-08 11.0157 -1.96701e-07 13.501C-3.05336e-07 15.9863 2.01472 18.001 4.5 18.001C6.98528 18.001 9 15.9863 9 13.501C9 13.1153 8.95148 12.741 8.86022 12.3837L6.82679 14.4172C6.4613 15.3446 5.5573 16.001 4.5 16.001C3.11929 16.001 2 14.8817 2 13.501C2 12.6828 2.39304 11.9564 3.00061 11.5003L3.00061 11.4997L5.27669 9.06776Z"
        fill="white"
      />
    </svg>
  )
}

export default OutcomeArrow
