function OpenExternalLink({
  className,
  size = '12',
  color = 'white',
}: {
  size?: string
  color?: string
  className?: string
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      fill="none"
      viewBox={`0 0 ${size} ${size}`}
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.31101 0H11.6822C11.8577 0 12 0.142299 12 0.317821V3.68889C12 3.86441 11.8577 4.00671 11.6822 4.00671H10.8347C10.6591 4.00671 10.5168 3.86441 10.5168 3.68889V2.53189L6.16286 6.88583C6.10328 6.94545 6.02243 6.97893 5.93814 6.97893C5.85381 6.97893 5.773 6.94545 5.71337 6.88583L5.11409 6.28651C4.98997 6.16234 4.98997 5.96114 5.11409 5.83702L9.46806 1.48317H8.31101C8.13549 1.48317 7.99319 1.34087 7.99319 1.16534V0.317821C7.99319 0.142299 8.13549 0 8.31101 0ZM9.95046 5.96289H10.798C10.9735 5.96289 11.1158 6.10519 11.1158 6.28075V10.1449C11.1158 11.1678 10.2835 12 9.26057 12H1.85503C0.832189 12 0 11.1678 0 10.1449V2.73941C0 1.71645 0.832147 0.884186 1.85503 0.884186H5.91703C6.09255 0.884186 6.23485 1.02648 6.23485 1.20201V2.04953C6.23485 2.22505 6.09255 2.36735 5.91703 2.36735H1.85503C1.64997 2.36735 1.48318 2.53427 1.48318 2.73941V10.1448C1.48318 10.3499 1.64997 10.5168 1.85503 10.5168H9.26062C9.46572 10.5168 9.63264 10.3499 9.63264 10.1448V6.28071C9.63264 6.10518 9.77494 5.96289 9.95046 5.96289Z"
        fill={color}
      />
    </svg>
  )
}

export default OpenExternalLink
