interface Props {
  size?: number
}

function Governance({ size = 48 }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 48 48">
      <circle cx="24" cy="24" r="24" fill="#FFE0D2"></circle>
      <path
        fill="#FF7439"
        d="M35.672 21.328l-1.078-1.031a1.085 1.085 0 00-.797-.328c-.313 0-.578.11-.797.328l-.234.234-5.297-5.297.234-.234c.219-.219.328-.484.328-.797 0-.312-.11-.578-.328-.797l-1.031-1.078a1.085 1.085 0 00-.797-.328c-.313 0-.578.11-.797.328l-5.86 5.813c-.218.25-.327.53-.327.843 0 .282.109.532.328.75l1.078 1.078c.219.22.484.329.797.329.312 0 .578-.11.797-.328l.234-.282L24 22.406l-3.797 3.797-.281-.281c-.281-.281-.64-.422-1.078-.422-.407 0-.75.14-1.032.422l-5.39 5.39c-.281.282-.422.641-.422 1.079 0 .406.14.75.422 1.03l2.156 2.157c.281.281.625.422 1.031.422.438 0 .797-.14 1.079-.422l5.39-5.39c.281-.282.422-.625.422-1.032 0-.437-.14-.797-.422-1.078l-.281-.281L25.594 24l1.875 1.875-.282.234a1.085 1.085 0 00-.328.797c0 .313.11.578.328.797l1.079 1.078c.218.219.468.328.75.328.312 0 .593-.109.843-.328l5.813-5.86c.219-.218.328-.483.328-.796 0-.313-.11-.578-.328-.797z"
      ></path>
    </svg>
  )
}

export default Governance
