export default function Weth({ size = 45 }: { size?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="weth"
      x={0}
      y={0}
      enableBackground="new 0 0 64 64"
      width={size}
      height={size}
      viewBox="0 0 64 64"
    >
      <title>{'weth'}</title>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#000"
          fillRule="nonzero"
          d="M17.942 63.475c16.086 0 16.086-.24 18.814-1.049 3.564-1.058-10.064-10.492-18.814-10.492s-15.844 2.584-15.844 5.77c0 3.188 7.094 5.771 15.844 5.771Z"
        />
        <path
          fill="#000"
          fillRule="nonzero"
          d="m54.47 14.405.74.797-.647.874c-20.98 28.367-33.066 41.21-38.194 39.488-3.123-1.05-6.255-4.205-9.388-8.787a64.713 64.713 0 0 1-3.075-4.994 54.367 54.367 0 0 1-1.431-2.726c-.351-.737-.5-1.19-.617-1.786l-.014-.072a6.09 6.09 0 0 0-.103-.471 28.63 28.63 0 0 1-.954-7.351c0-15.79 12.8-28.59 28.59-28.59 4.947 0 9.714 1.259 13.939 3.622l.109.068c3.459 2.409 7.138 5.718 11.045 9.928ZM41.979 6.667A25.841 25.841 0 0 0 29.377 3.41C15.036 3.41 3.41 15.036 3.41 29.377c0 2.279.293 4.518.866 6.678.057.214.083.335.143.644l.012.064c.076.384.156.63.411 1.165a51.97 51.97 0 0 0 1.355 2.578 62.126 62.126 0 0 0 2.95 4.79c2.83 4.14 5.636 6.968 8.058 7.781 3.229 1.085 15.386-11.784 34.598-37.682-3.488-3.681-6.765-6.59-9.824-8.728Z"
        />
        <path
          fill="#EC1C79"
          fillRule="nonzero"
          d="M41.979 6.667A25.841 25.841 0 0 0 29.377 3.41C15.036 3.41 3.41 15.036 3.41 29.377c0 2.279.293 4.518.866 6.678.057.214.083.335.143.644l.012.064c.076.384.156.63.411 1.165a51.97 51.97 0 0 0 1.355 2.578 62.126 62.126 0 0 0 2.95 4.79c2.83 4.14 5.636 6.968 8.058 7.781 3.229 1.085 15.386-11.784 34.598-37.682-3.488-3.681-6.765-6.59-9.824-8.728Z"
        />
        <path
          fill="#000"
          fillRule="nonzero"
          d="M34.623 63.213c-15.79 0-28.59-12.8-28.59-28.59s12.8-28.59 28.59-28.59 28.59 12.8 28.59 28.59-12.8 28.59-28.59 28.59Zm0-2.623c14.341 0 25.967-11.626 25.967-25.967S48.964 8.656 34.623 8.656 8.656 20.282 8.656 34.623 20.282 60.59 34.623 60.59Z"
        />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M34.623 60.59c14.341 0 25.967-11.626 25.967-25.967S48.964 8.656 34.623 8.656 8.656 20.282 8.656 34.623 20.282 60.59 34.623 60.59Z"
        />
        <path
          fill="#000"
          d="m15.198 39.942-3.437-10.355h3.011l1.792 5.949 1.983-5.978H21l1.983 5.978 1.792-5.95h2.953L24.29 39.943h-2.483l-2.07-5.92-2.057 5.92h-2.483Zm13.66-.073V29.587h8.27v2.423H31.68v1.557h4.935v2.248H31.68v1.63h5.523v2.424h-8.343Zm12.31 0v-7.785h-3.085v-2.497h9.019v2.497h-3.085v7.785h-2.85Zm7.182 0V29.587h2.85v3.848h3.657v-3.848h2.85v10.282h-2.85v-3.907H51.2v3.907h-2.85Z"
        />
        <path
          fill="#000"
          fillRule="nonzero"
          d="M2.49 26.494a1.469 1.469 0 1 1 1.314-2.627l4.197 2.098a1.469 1.469 0 0 1-1.314 2.627l-4.196-2.098ZM2.007 33.453a1.469 1.469 0 0 1 1.231-2.668l4.197 1.937a1.469 1.469 0 0 1-1.23 2.668l-4.198-1.937Z"
        />
      </g>
    </svg>
  )
}
