import { PillColor } from '../Common/Pill'

interface Props {
  color?: string
}

export default function Clock({ color }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 13 12" fill="none">
      <g clipPath="url(#clip0_15563_413)">
        <path
          d="M11.375 6C11.375 7.29293 10.8614 8.53291 9.94715 9.44715C9.03291 10.3614 7.79293 10.875 6.5 10.875C5.20707 10.875 3.96709 10.3614 3.05285 9.44715C2.13861 8.53291 1.625 7.29293 1.625 6C1.625 4.70707 2.13861 3.46709 3.05285 2.55285C3.96709 1.63861 5.20707 1.125 6.5 1.125C7.79293 1.125 9.03291 1.63861 9.94715 2.55285C10.8614 3.46709 11.375 4.70707 11.375 6ZM0.5 6C0.5 7.5913 1.13214 9.11742 2.25736 10.2426C3.38258 11.3679 4.9087 12 6.5 12C8.0913 12 9.61742 11.3679 10.7426 10.2426C11.8679 9.11742 12.5 7.5913 12.5 6C12.5 4.4087 11.8679 2.88258 10.7426 1.75736C9.61742 0.632141 8.0913 0 6.5 0C4.9087 0 3.38258 0.632141 2.25736 1.75736C1.13214 2.88258 0.5 4.4087 0.5 6ZM5.9375 2.8125V6C5.9375 6.1875 6.03125 6.36328 6.18828 6.46875L8.43828 7.96875C8.69609 8.14219 9.04531 8.07187 9.21875 7.81172C9.39219 7.55156 9.32187 7.20469 9.06172 7.03125L7.0625 5.7V2.8125C7.0625 2.50078 6.81172 2.25 6.5 2.25C6.18828 2.25 5.9375 2.50078 5.9375 2.8125Z"
          fill={color || `var(--${PillColor.Red}-800)`}
        />
      </g>
      <defs>
        <clipPath id="clip0_15563_413">
          <rect width="12" height="12" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  )
}
