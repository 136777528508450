function IncomeArrow() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L1.70711 0.292893ZM13 14C13.5523 14 14 13.5523 14 13L14 4C14 3.44771 13.5523 3 13 3C12.4477 3 12 3.44771 12 4L12 12L4 12C3.44771 12 3 12.4477 3 13C3 13.5523 3.44771 14 4 14L13 14ZM0.292893 1.70711L12.2929 13.7071L13.7071 12.2929L1.70711 0.292893L0.292893 1.70711Z"
        fill="white"
      />
      <path
        opacity="0.3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25562 15.0001C9.87319 16.7482 11.5402 18.0007 13.4998 18.0007C15.9851 18.0007 17.9998 15.986 17.9998 13.5007C17.9998 11.5415 16.7476 9.87463 14.9999 9.25684V11.5007C15.6071 11.9568 15.9998 12.6829 15.9998 13.5007C15.9998 14.8814 14.8805 16.0007 13.4998 16.0007C12.6816 16.0007 11.9552 15.6077 11.4991 15.0001H9.25562Z"
        fill="white"
      />
    </svg>
  )
}

export default IncomeArrow
