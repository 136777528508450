function CircledComment() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="12" fill="#F3F2F5" />
      <g clipPath="url(#clip0_15188_436)">
        <path
          d="M18.0007 11.625C18.0007 14.318 15.3148 16.5 12.0007 16.5C11.1312 16.5 10.3062 16.35 9.56085 16.0805C9.28194 16.2844 8.82726 16.5633 8.2882 16.7977C7.7257 17.0414 7.04835 17.25 6.3757 17.25C6.22335 17.25 6.08741 17.1586 6.02882 17.018C5.97023 16.8773 6.00304 16.718 6.10851 16.6102L6.11554 16.6031C6.12257 16.5961 6.13195 16.5867 6.14601 16.5703C6.17179 16.5422 6.21163 16.4977 6.26085 16.4367C6.35695 16.3195 6.48585 16.1461 6.6171 15.9305C6.85148 15.5414 7.07413 15.0305 7.11866 14.4562C6.41554 13.6594 6.0007 12.682 6.0007 11.625C6.0007 8.93203 8.68663 6.75 12.0007 6.75C15.3148 6.75 18.0007 8.93203 18.0007 11.625Z"
          fill="#736E7D"
        />
      </g>
      <defs>
        <clipPath id="clip0_15188_436">
          <rect width="12" height="12" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default CircledComment
