function WarningTriangle({ className, size = '14' }: { size?: string; className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 14 14"
      fill="none"
      width={size}
      height={size}
      className={className}
    >
      <g clipPath="url(#clip0_12016_2691)">
        <path
          d="M6.99997 0.875C7.38825 0.875 7.74645 1.08008 7.94333 1.41641L13.8496 11.4789C14.0492 11.818 14.0492 12.2363 13.855 12.5754C13.6609 12.9145 13.2972 13.125 12.9062 13.125H1.09372C0.702702 13.125 0.33903 12.9145 0.144889 12.5754C-0.0492514 12.2363 -0.0465171 11.8152 0.150358 11.4789L6.05661 1.41641C6.25348 1.08008 6.61169 0.875 6.99997 0.875ZM6.99997 4.375C6.6363 4.375 6.34372 4.66758 6.34372 5.03125V8.09375C6.34372 8.45742 6.6363 8.75 6.99997 8.75C7.36364 8.75 7.65622 8.45742 7.65622 8.09375V5.03125C7.65622 4.66758 7.36364 4.375 6.99997 4.375ZM7.87497 10.5C7.87497 10.2679 7.78278 10.0454 7.61869 9.88128C7.45459 9.71719 7.23203 9.625 6.99997 9.625C6.7679 9.625 6.54534 9.71719 6.38125 9.88128C6.21715 10.0454 6.12497 10.2679 6.12497 10.5C6.12497 10.7321 6.21715 10.9546 6.38125 11.1187C6.54534 11.2828 6.7679 11.375 6.99997 11.375C7.23203 11.375 7.45459 11.2828 7.61869 11.1187C7.78278 10.9546 7.87497 10.7321 7.87497 10.5Z"
          fill="#FFB03F"
        />
      </g>
      <defs>
        <clipPath id="clip0_12016_2691">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default WarningTriangle
