function Sign({ className }: { className?: string }) {
  return (
    <svg
      className={className}
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="32" cy="32" r="32" fill="#FCE9EC" />
      <path
        d="M27.8 26.25C27.8 25.524 28.3809 24.9375 29.1 24.9375C29.8191 24.9375 30.4 25.524 30.4 26.25V26.5699C30.4 27.7061 30.3025 28.8381 30.1116 29.9537L26.6828 30.9914C25.0334 31.4918 23.9041 33.0258 23.9041 34.7648V37.7139C23.9041 39.3545 25.2244 40.6875 26.8494 40.6875C27.9056 40.6875 28.8806 40.1174 29.4047 39.1904L29.9694 38.1938C31.0581 36.266 31.8584 34.1865 32.3419 32.0209L36.1769 30.8602L35.6691 32.3982C35.535 32.8002 35.6041 33.2391 35.8478 33.5795C36.0916 33.9199 36.4856 34.125 36.9041 34.125H42.1C42.8191 34.125 43.4 33.5385 43.4 32.8125C43.4 32.0865 42.8191 31.5 42.1 31.5H38.7037L39.435 29.2893C39.5894 28.8258 39.4716 28.3131 39.1344 27.9604C38.7972 27.6076 38.2934 27.4764 37.8262 27.6158L32.8537 29.1252C32.9512 28.2762 33 27.4271 33 26.5699V26.25C33 24.0762 31.2531 22.3125 29.1 22.3125C26.9469 22.3125 25.2 24.0762 25.2 26.25V27.5625C25.2 28.2885 25.7809 28.875 26.5 28.875C27.2191 28.875 27.8 28.2885 27.8 27.5625V26.25ZM27.4262 33.5098L29.4169 32.9068C28.9944 34.2932 28.4216 35.6303 27.7066 36.8936L27.1419 37.8902C27.0809 37.9969 26.9672 38.0666 26.8412 38.0666C26.6503 38.0666 26.4959 37.9107 26.4959 37.718V34.7648C26.4959 34.1865 26.8737 33.6738 27.4222 33.5057L27.4262 33.5098ZM20.975 36.0938C20.4347 36.0938 20 36.5326 20 37.0781C20 37.6236 20.4347 38.0625 20.975 38.0625H22.6122C22.6041 37.9477 22.6 37.8328 22.6 37.7139V36.0938H20.975ZM45.025 38.0625C45.5653 38.0625 46 37.6236 46 37.0781C46 36.5326 45.5653 36.0938 45.025 36.0938H32.4272C32.155 36.7623 31.8503 37.4186 31.5212 38.0625H45.025Z"
        fill="#FF2D55"
      />
    </svg>
  )
}

export default Sign
