function ReadReason() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_15492_3354)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99991 14C12.4187 14 15.9999 11.0906 15.9999 7.5C15.9999 3.90937 12.4187 1 7.99991 1C3.58116 1 -8.54305e-05 3.90937 -8.54305e-05 7.5C-8.54305e-05 8.90938 0.55304 10.2125 1.49054 11.2781C1.43116 12.0437 1.13429 12.725 0.82179 13.2437C0.649915 13.5312 0.474915 13.7625 0.34679 13.9187C0.281165 13.9969 0.231165 14.0563 0.193665 14.0969C0.182682 14.1079 0.173844 14.1178 0.166522 14.126C0.161343 14.1318 0.156923 14.1367 0.15304 14.1406L0.143665 14.15C-8.54291e-05 14.2937 -0.0407104 14.5063 0.0374146 14.6938C0.11554 14.8813 0.29679 15.0031 0.499915 15.0031C1.39679 15.0031 2.29991 14.725 3.04991 14.4C3.76554 14.0875 4.37491 13.7156 4.74679 13.4438C5.74054 13.8031 6.84054 14.0031 7.99991 14.0031V14ZM5 6.5C4.44772 6.5 4 6.94772 4 7.5C4 8.05228 4.44772 8.5 5 8.5H11C11.5523 8.5 12 8.05228 12 7.5C12 6.94772 11.5523 6.5 11 6.5H5Z"
          fill="#B9B7BE"
        />
      </g>
      <defs>
        <clipPath id="clip0_15492_3354">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ReadReason
