interface Props {
  size?: number
}

function RemovePoi({ size = 48 }: Props) {
  return (
    <svg width={size} height={size} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="24" fill="#FFC4D1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5117 30.1953C21.6862 31.8568 22.6458 33.2318 23.3906 34.3203C23.5911 34.6068 23.8776 34.75 24.25 34.75C24.6224 34.75 24.9089 34.6068 25.1094 34.3203L27.9883 30.1953C29.4206 28.1328 30.3659 26.7435 30.8242 26.0273C31.4831 24.9961 31.9271 24.1367 32.1562 23.4492C32.3854 22.7331 32.5 21.9167 32.5 21C32.5 19.5104 32.1276 18.1354 31.3828 16.875C30.638 15.6146 29.6354 14.612 28.375 13.8672C27.1146 13.1224 25.7396 12.75 24.25 12.75C22.7604 12.75 21.3854 13.1224 20.125 13.8672C18.8646 14.612 17.862 15.6146 17.1172 16.875C16.3724 18.1354 16 19.5104 16 21C16 21.9167 16.1146 22.7331 16.3438 23.4492C16.5729 24.1367 17.0169 24.9961 17.6758 26.0273C18.1341 26.7435 19.0794 28.1328 20.5117 30.1953ZM20 20C19.4477 20 19 20.4477 19 21C19 21.5523 19.4477 22 20 22H29C29.5523 22 30 21.5523 30 21C30 20.4477 29.5523 20 29 20H20Z"
        fill="#D80027"
      />
    </svg>
  )
}

export default RemovePoi
