export interface GrantRequestSectionIconProps {
  sectionNumber: number
  color?: string
}

function NumberIcon({ sectionNumber, color = '#D2D0D5' }: GrantRequestSectionIconProps) {
  return (
    <>
      {sectionNumber === 1 ? (
        <path
          d="M15.4497 26H17.3027V15.4312H15.4351L12.6738 17.3721V19.1079L15.4058 17.2329H15.4497V26Z"
          fill={color}
        />
      ) : sectionNumber === 2 ? (
        <path
          d="M12.7837 26H20.2104V24.4546H15.2812V24.374L17.6104 22.1621C19.4707 20.375 20.0493 19.5327 20.0493 18.2876V18.2729C20.0493 16.5298 18.5698 15.248 16.4531 15.248C14.3071 15.248 12.6812 16.6323 12.6812 18.5659V18.6172H14.4536V18.5659C14.4756 17.5479 15.2959 16.7642 16.4678 16.7642C17.4858 16.7642 18.1963 17.438 18.2036 18.3828V18.3975C18.2036 19.1812 17.9033 19.7158 16.519 21.0708L12.7837 24.7109V26Z"
          fill={color}
        />
      ) : sectionNumber === 3 ? (
        <path
          d="M16.585 26.1831C18.9214 26.1831 20.5327 24.894 20.5327 23.0557V23.041C20.5327 21.5762 19.5 20.6533 17.9399 20.5068V20.4702C19.207 20.2065 20.1592 19.335 20.1592 18.0239V18.0093C20.1592 16.3687 18.7456 15.248 16.563 15.248C14.4316 15.248 12.9888 16.4346 12.835 18.2217L12.8276 18.3096H14.5854L14.5928 18.2363C14.688 17.3281 15.4497 16.7495 16.563 16.7495C17.6982 16.7495 18.3428 17.3062 18.3428 18.2583V18.2729C18.3428 19.1958 17.5664 19.855 16.4019 19.855H15.1934V21.2612H16.4458C17.7935 21.2612 18.6138 21.8765 18.6138 22.9897V23.0044C18.6138 23.9639 17.8008 24.6377 16.5776 24.6377C15.3398 24.6377 14.5195 24.0151 14.417 23.1582L14.4097 23.0776H12.6006L12.6079 23.1655C12.7544 24.938 14.2632 26.1831 16.585 26.1831Z"
          fill={color}
        />
      ) : sectionNumber === 4 ? (
        <path
          d="M16.8154 26H18.6172V23.9712H20.0527V22.4185H18.6172V15.4312H15.9292L11.542 22.3599V23.9712H16.8154V26ZM13.2412 22.4624V22.4111L16.8008 16.8594H16.8447V22.4624H13.2412Z"
          fill={color}
        />
      ) : sectionNumber === 5 ? (
        <path
          d="M15.6875 26.1831C18.0239 26.1831 19.6572 24.689 19.6572 22.5137V22.499C19.6572 20.4922 18.207 19.0347 16.1929 19.0347C15.1309 19.0347 14.2593 19.4521 13.7979 20.1626H13.7612L14.0542 16.9766H19.0347V15.4312H12.5454L12.04 21.5762H13.688C13.8125 21.3418 13.9883 21.1367 14.186 20.9756C14.5889 20.6387 15.1089 20.4556 15.7095 20.4556C16.9473 20.4556 17.8335 21.3198 17.8335 22.5283V22.543C17.8335 23.7808 16.9619 24.645 15.7021 24.645C14.5889 24.645 13.7832 23.9639 13.6294 23.0776L13.6221 23.0264H11.8496L11.8569 23.1216C11.9961 24.8721 13.4902 26.1831 15.6875 26.1831Z"
          fill={color}
        />
      ) : (
        <path
          d="M15.8926 26.1831C18.1704 26.1831 19.8403 24.6523 19.8403 22.521V22.5063C19.8403 20.5215 18.4121 19.0566 16.376 19.0566C14.9404 19.0566 13.8857 19.7891 13.4683 20.7559H13.4316C13.4316 20.6387 13.4316 20.5288 13.439 20.4189C13.5122 18.4194 14.2227 16.7935 15.9219 16.7935C16.8667 16.7935 17.5186 17.2622 17.7969 17.9873L17.8262 18.0532H19.7012L19.6865 17.9727C19.3496 16.376 17.8921 15.248 15.9365 15.248C13.2412 15.248 11.6226 17.3354 11.6226 20.8291V20.8438C11.6226 24.5498 13.5562 26.1831 15.8926 26.1831ZM13.7539 22.5503V22.543C13.7539 21.3638 14.6768 20.5142 15.8853 20.5142C17.0938 20.5142 17.98 21.3711 17.98 22.5723V22.5869C17.98 23.7295 17.0425 24.6377 15.8633 24.6377C14.6841 24.6377 13.7539 23.7222 13.7539 22.5503Z"
          fill={color}
        />
      )}
    </>
  )
}

export default NumberIcon
