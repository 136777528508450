interface Props {
  size?: number
}

function Grant({ size = 48 }: Props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 48 48">
      <circle cx="24" cy="24" r="24" fill="#3C24B3" opacity="0.16"></circle>
      <path
        fill="#3C24B3"
        fillRule="evenodd"
        d="M30.73 26.458a6.076 6.076 0 00-1.45-.175c-3.336 0-6.04 2.693-6.04 6.015 0 .512.065 1.009.186 1.484h-6.114a3.309 3.309 0 01-3.312-3.3V16.3A3.309 3.309 0 0117.311 13H27.42a3.309 3.309 0 013.312 3.299v10.159zm-3.557-10.66H17.66a.704.704 0 00-.705.704c0 .388.316.703.705.703h9.513c.39 0 .706-.315.706-.703a.705.705 0 00-.706-.703zm-9.513 2.414h8.101c.39 0 .706.315.706.703a.704.704 0 01-.706.703h-8.1a.705.705 0 01-.706-.703c0-.388.316-.703.705-.703zm9.462 2.413H17.66a.704.704 0 00-.705.703c0 .388.316.703.705.703h9.462c.39 0 .705-.315.705-.703a.705.705 0 00-.706-.703zm-7.164 10.438H17.66a.705.705 0 01-.705-.703c0-.389.316-.703.705-.703h2.298c.39 0 .706.314.706.703a.705.705 0 01-.706.703zm9.322-3.468c-2.603 0-4.72 2.11-4.72 4.703 0 2.593 2.117 4.702 4.72 4.702 2.602 0 4.72-2.11 4.72-4.702 0-2.594-2.118-4.703-4.72-4.703zm2.09 3.965l-2.189 2.18a.705.705 0 01-.994.007l-.992-.966a.702.702 0 01-.012-.994.708.708 0 01.999-.011l.493.48 1.697-1.69a.708.708 0 01.998 0 .701.701 0 010 .994z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Grant
