export default function Hourglass() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="108" height="108" fill="none" viewBox="0 0 108 108">
      <circle cx="54" cy="54" r="54" fill="#FEF7D2"></circle>
      <path
        fill="#FFB03F"
        d="M72.313 27C73.802 27 75 28.128 75 29.531c0 1.403-1.198 2.532-2.688 2.532h-.895v2.003c0 4.25-1.792 8.332-4.983 11.338L57.296 54l9.138 8.596c3.191 3.006 4.983 7.087 4.983 11.338v2.004h.895c1.49 0 2.688 1.128 2.688 2.53C75 79.872 73.802 81 72.312 81H34.688C33.197 81 32 79.871 32 78.469c0-1.403 1.198-2.531 2.688-2.531h.895v-2.004c0-4.25 1.792-8.332 4.983-11.338L49.704 54l-9.126-8.596c-3.203-3.006-4.995-7.087-4.995-11.338v-2.004h-.895c-1.49 0-2.688-1.128-2.688-2.53C32 28.127 33.198 27 34.688 27h37.624zM53.5 57.575l-9.126 8.596c-2.184 2.067-3.416 4.852-3.416 7.763v-5.71h25.084v5.71c0-2.911-1.232-5.696-3.416-7.752L53.5 57.575zm0-15.757H62.626c2.184-2.056 3.416-4.84 3.416-7.752v-2.004H40.958v2.004c0 2.911 1.232 5.696 3.416 7.752H53.5z"
      ></path>
    </svg>
  )
}
