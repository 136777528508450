function BlueLinkIcon() {
  return (
    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.0699 7.29704C15.4824 5.86247 15.4824 3.53923 14.0699 2.10466C12.8199 0.835129 10.8499 0.67009 9.41238 1.71364L9.37238 1.74157C9.01238 2.0031 8.92988 2.51091 9.18738 2.874C9.44488 3.23708 9.94488 3.32341 10.3024 3.06189L10.3424 3.03396C11.1449 2.45251 12.2424 2.54392 12.9374 3.25232C13.7249 4.05212 13.7249 5.34704 12.9374 6.14685L10.1324 9.00075C9.34488 9.80056 8.06988 9.80056 7.28238 9.00075C6.58488 8.29236 6.49488 7.17771 7.06738 6.36521L7.09488 6.32458C7.35238 5.95896 7.26738 5.45115 6.90988 5.19216C6.55238 4.93318 6.04988 5.01697 5.79488 5.38005C5.53988 5.74314 5.76738 5.42068 5.76738 5.42068C4.73738 6.8781 4.89988 8.87888 6.14988 10.1484C7.56238 11.583 9.84988 11.583 11.2624 10.1484L14.0699 7.29704ZM1.07988 6.7029C-0.332617 8.13747 -0.332617 10.4607 1.07988 11.8953C2.32988 13.1648 4.29988 13.3299 5.73738 12.2863L5.77738 12.2584C6.13738 11.9968 6.21988 11.489 5.96238 11.1259C5.70488 10.7629 5.20488 10.6765 4.84738 10.9381L4.80738 10.966C4.00488 11.5474 2.90738 11.456 2.21238 10.7476C1.42488 9.94529 1.42488 8.65036 2.21238 7.85056L5.01738 4.99919C5.80488 4.19939 7.07988 4.19939 7.86738 4.99919C8.56488 5.70759 8.65488 6.82224 8.08238 7.63728L8.05488 7.6779C7.79738 8.04353 7.88238 8.55134 8.23988 8.81032C8.59738 9.06931 9.09988 8.98552 9.35488 8.62243L9.38238 8.58181C10.4124 7.12185 10.2499 5.12107 8.99988 3.85154C7.58738 2.41696 5.29988 2.41696 3.88738 3.85154L1.07988 6.7029Z"
        fill="#4888EE"
      />
    </svg>
  )
}

export default BlueLinkIcon
