function BellPurple() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 11 13" fill="none">
      <g clipPath="url(#clip0_15714_4033)">
        <path
          d="M5.49953 0C5.06493 0 4.71382 0.363086 4.71382 0.8125V1.3C2.9214 1.67578 1.57096 3.31602 1.57096 5.28125V5.75859C1.57096 6.95195 1.14618 8.10469 0.38011 8.99844L0.198414 9.20918C-0.00783606 9.44785 -0.0569432 9.79063 0.06828 10.0826C0.193503 10.3746 0.475869 10.5625 0.785244 10.5625H10.2138C10.5232 10.5625 10.8031 10.3746 10.9308 10.0826C11.0585 9.79063 11.0069 9.44785 10.8006 9.20918L10.6189 8.99844C9.85288 8.10469 9.4281 6.95449 9.4281 5.75859V5.28125C9.4281 3.31602 8.07765 1.67578 6.28524 1.3V0.8125C6.28524 0.363086 5.93413 0 5.49953 0ZM6.61181 12.5252C6.90645 12.2205 7.07096 11.8066 7.07096 11.375H5.49953H3.9281C3.9281 11.8066 4.09261 12.2205 4.38725 12.5252C4.6819 12.8299 5.08212 13 5.49953 13C5.91694 13 6.31716 12.8299 6.61181 12.5252Z"
          fill="#5D5FEF"
        />
      </g>
      <defs>
        <clipPath id="clip0_15714_4033">
          <rect width="11" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default BellPurple
