function Scale() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="76" height="60" fill="none" viewBox="0 0 76 60">
      <path
        fill="#BFBEC1"
        d="M41.164 16.516a8.102 8.102 0 003.82-3.03c.967-1.405 1.45-2.986 1.45-4.742h15.281c.44 0 .79-.132 1.054-.396.351-.35.527-.746.527-1.185V4c0-.439-.176-.79-.527-1.054a1.252 1.252 0 00-1.054-.527h-18.18C41.955 1.015 40.11.313 38.003.313c-2.108 0-3.953.702-5.533 2.107H14.29c-.44 0-.834.176-1.186.527-.263.264-.395.615-.395 1.054v3.162c0 .439.132.834.395 1.185.352.264.747.396 1.186.396h15.281c0 1.756.483 3.337 1.45 4.742a8.102 8.102 0 003.82 3.03v36.49H14.29c-.44 0-.834.177-1.186.528-.263.263-.395.615-.395 1.054v3.161c0 .44.132.79.395 1.054.352.352.747.527 1.186.527h47.425c.44 0 .79-.175 1.054-.527.351-.263.527-.614.527-1.054v-3.161c0-.44-.176-.79-.527-1.054a1.252 1.252 0 00-1.054-.527h-20.55V16.516zm-3.162-9.88c.615 0 1.098.22 1.45.659.439.35.658.834.658 1.449 0 .614-.22 1.141-.658 1.58-.352.352-.835.527-1.45.527-.614 0-1.141-.175-1.58-.527-.352-.439-.527-.966-.527-1.58 0-.615.175-1.098.527-1.45.439-.439.966-.658 1.58-.658zm-8.43 29.509c0-.79-.176-1.625-.528-2.503-.35-.879-1.624-3.47-3.82-7.773l-4.742-9.353c-.79-1.58-1.933-2.635-3.425-3.162a6.542 6.542 0 00-4.48 0c-1.493.527-2.634 1.581-3.425 3.162L4.41 25.869C2.214 30.173.94 32.764.59 33.642c-.352.878-.527 1.712-.527 2.503 0 1.932.658 3.688 1.976 5.27 1.317 1.58 3.117 2.853 5.4 3.82 2.284.966 4.743 1.449 7.378 1.449s5.094-.483 7.377-1.45c2.284-.966 4.084-2.239 5.401-3.82 1.318-1.58 1.976-3.337 1.976-5.27zM14.816 19.282l8.431 16.863H6.386l8.43-16.863zm61.125 16.863c0-.79-.175-1.625-.526-2.503-.352-.879-1.625-3.47-3.82-7.773l-4.743-9.353c-.79-1.58-1.932-2.635-3.425-3.162a6.542 6.542 0 00-4.48 0c-1.492.527-2.634 1.581-3.425 3.162l-4.742 9.353c-2.196 4.304-3.47 6.895-3.82 7.773-.352.878-.527 1.712-.527 2.503 0 1.932.658 3.688 1.976 5.27 1.317 1.58 3.117 2.853 5.4 3.82 2.284.966 4.743 1.449 7.378 1.449s5.094-.483 7.377-1.45c2.284-.966 4.084-2.239 5.401-3.82 1.318-1.58 1.977-3.337 1.977-5.27zM61.188 19.282l8.431 16.863H52.757l8.431-16.863z"
      ></path>
    </svg>
  )
}

export default Scale
