import { config } from '../config'

export const MIN_NAME_SIZE = 2
export const MAX_NAME_SIZE = 15
export const SUBMISSION_THRESHOLD_DRAFT = config.get('SUBMISSION_THRESHOLD_DRAFT')
export const SUBMISSION_THRESHOLD_GOVERNANCE = config.get('SUBMISSION_THRESHOLD_GOVERNANCE')
export const SUBMISSION_THRESHOLD_POLL = config.get('SUBMISSION_THRESHOLD_POLL')
export const SUBMISSION_THRESHOLD_PITCH = config.get('SUBMISSION_THRESHOLD_PITCH')
export const SUBMISSION_THRESHOLD_TENDER = config.get('SUBMISSION_THRESHOLD_TENDER')
export const SUBMISSION_THRESHOLD_HIRING = config.get('SUBMISSION_THRESHOLD_HIRING')
export const SUBMISSION_THRESHOLD_GRANT = config.get('SUBMISSION_THRESHOLD_GRANT')
export const MILESTONE_SUBMIT_LIMIT = 10
